@use "styles/sass/abstracts/variables" as var;

div.container {
	div.basic-single {
    &:not(.short){

      div.select__control {
        padding: 0.325rem 0.1rem;
      }
    }

		&.error .select__control {
			@apply bg-feedback-negative-high-50 border-feedback-negative-pure-500 focus-visible:outline-feedback-negative-pure-500;
		}

    &.select--is-disabled {
      cursor: not-allowed;

      div.select__control--is-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
	}
}
